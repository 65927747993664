import React, { useEffect, useRef, useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import '../css/nav.scss';

interface NavbarProps {
  stick?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ stick }) => {
  const navbarRef = useRef<HTMLDivElement | null>(null);
  const placeholderRef = useRef<HTMLDivElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState('/landing');

  useEffect(() => {
    const navbar = navbarRef.current;
    const placeholder = placeholderRef.current;

    const handleScroll = () => {
      if (navbar && placeholder) {
        if (window.scrollY > 60) {
          setIsScrolled(true);
          placeholder.style.height = `${navbar.offsetHeight}px`;
          placeholder.style.display = 'block';
        } else {
          setIsScrolled(false);
          placeholder.style.display = 'none';
        }
      }
    };

    if (!stick) {
      handleScroll(); // Check scroll position on load

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    } else {
      // Ensure placeholder is set correctly if stick is true
      if (navbar && placeholder) {
        placeholder.style.height = `${navbar.offsetHeight}px`;
        placeholder.style.display = 'block';
      }
    }
  }, [stick]);

  useEffect(() => {
    setActiveLink(window.location.pathname);

    const handleRouteChange = () => {
      setActiveLink(window.location.pathname);
    };

    window.addEventListener('popstate', handleRouteChange);
    return () => window.removeEventListener('popstate', handleRouteChange);
  }, []);

  const handleLinkClick = (link: string) => {
    setActiveLink(link);
    setMenuOpen(false);
  };

  const getLinkName = (link: string) => {
    if (link === '/') return 'Rates';
    return link.charAt(1).toUpperCase() + link.slice(2);
  };

  
  return (
    <>
      <div ref={placeholderRef} className="h-0 transition-all duration-300 ease-in-out"></div>
      <header
        ref={navbarRef}
        className={`bg-gray-900 transition-all duration-300 ease-in-out z-50 ${
          isScrolled || stick 
            ? 'fixed top-0 left-0 right-0 w-full shadow-lg py-2 px-4' // Modified positioning
            : 'absolute top-12 left-6 right-6 mx-auto py-6 px-20 rounded-xl' // Modified positioning
        }`}
      >


        <nav className="flex justify-between items-center">
          <div className="logo">
            <img src="/logo.png" alt="Sidon's Revenge Logo" className="h-12" />
          </div>
          <ul className="hidden md:flex space-x-6 text-yellow-400 text-2xl">
            {['/', '/about', '/features'].map((link) => (
              <li key={link}>
                <a
                  href={link}
                  onClick={() => handleLinkClick(link)}
                  className={`nav-link hover:text-yellow-500 title ${
                    activeLink === link ? 'nav-link-active' : ''
                  }`}
                >
                  {getLinkName(link)}
                </a>
              </li>
            ))}
          </ul>
          <div className="flex items-center space-x-4">
            <a href="https://blog.valorserver.com" className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded title">Patch Notes</a>
            <a href="https://api.valorserver.com/api/download" className="bg-yellow-400 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded title">Download</a>
            <div className="md:hidden" onClick={() => setMenuOpen(!menuOpen)}>
              {menuOpen ? <FaTimes className="text-yellow-400 text-2xl" /> : <FaBars className="text-yellow-400 text-2xl" />}
            </div>
          </div>
        </nav>
        {menuOpen && (
          <div className="fixed inset-0 bg-gray-900 flex flex-col items-center justify-center space-y-4 text-yellow-400 text-xl z-50">
            <ul className="flex flex-col items-center space-y-4">
              {['/', '/about', '/features'].map((link) => (
                <li key={link}>
                  <a
                    href={link}
                    onClick={() => handleLinkClick(link)}
                    className={`hover:text-yellow-500 title ${
                      activeLink === link ? 'nav-link-active' : ''
                    }`}
                  >
                    {getLinkName(link)}
                  </a>
                </li>
              ))}
            </ul>
            <a href="https://blog.valorserver.com" className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded title" onClick={() => setMenuOpen(false)}>Patch Notes</a>
            <a href="https://api.valorserver.com/api/download" className="bg-yellow-400 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded title" onClick={() => setMenuOpen(false)}>Download</a>
          </div>
        )}
      </header>
    </>
  );
};

export default Navbar;
